<div class="container-auth">
    <form [formGroup]="formulario" (ngSubmit)="resetpassword()">
        <div class="form-group">
            <label for="email">Contraseña actual</label>
            <input placeholder="*******" class="form-control" type="password" formControlName="old_password"
                [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">
        </div>
        <div class="form-group">
            <div class="forget-pass">
                <label for="email">Nueva contraseña</label>
            </div>
            <input placeholder="*******" class="form-control" type="password" formControlName="new_password"
                [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">
        </div>
        <button type="submit" class="btn-primary">Cambiar contraseña</button>
    </form>
</div>
<div class="loader-demo-box" *ngIf="preloadingstate">
    <div class="circle-loader"></div>
</div>