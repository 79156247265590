<h2 class="title">
    Mesa de Partes Virtual
</h2>
<div class="mesapartes">
    <br>
    <iframe style="width: 100%; height: 100vh;" id="inlineFrameExample"
    title="Inline Frame Example"
    src="http://admintest.ccpll.org/mpv">
</iframe>
    <!-- <a href="https://mail.google.com/mail/?view=cm&fs=1&to=mesadepartes@ccpll.org"
        target="_blank">mesadepartes@ccpll.org</a> -->
</div>