<h2 class="title">
    Constancia de Habilidad Colegiados
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/constancia de habilidad de colegiado.png" alt=""></div>
    <div>
        <span class="subtitle">REQUISITOS</span>
        <ul>
            <li>SE SOLICITA EN TESORERÍA DE FORMA PRESENCIAL O VIRTUAL</li>
            <li>COSTO S/. 10.00, SI ESTÁ AL DÍA EN SUS CUOTAS.</li>
            <li>VIGENCIA DE 3 MESES A PARTIR DEL ÚLTIMO MES PAGADO</li>
            <li>ENTREGA INMEDIATA</li>
        </ul>
    </div>
</div>
<h2 class="title">
    Constancia de Habilidad Sociedades de Auditoría
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icon/icon8.png" alt=""></div>
    <div>
        <span class="subtitle">REQUISITOS</span>
        <ul>
            <li>SE SOLICITA EN TESORERÍA DE FORMA PRESENCIAL O VIRTUAL</li>
            <li>COSTO S/. 20.00, SI ESTÁ AL DÍA EN SUS CUOTAS COMO SOCIEDAD, ASÍ COMO LOS SOCIOS QUE LOS INTEGRAN.
            </li>
            <li>VIGENCIA DE 3 MESES A PARTIR DEL ÚLTIMO MES PAGADO</li>
            <li>ENTREGA INMEDIATA</li>
        </ul>
    </div>
</div>