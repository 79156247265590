<h2 class="title">
    Consejo Directivo
</h2>
<div class="consejo">
    <div class="col-md-4 perfil" *ngFor="let item of consejod" [swal]="show" (click)="info = item">
        <img [src]="'./assets/img/team/' + item.image" alt="">
        <div class="perfil-inf">
            <p>{{item.nombre}}</p>
            <span>{{item.cargo}}</span>
        </div>
    </div>
</div>
<h2 class="title" style="margin-top: 3rem;">
    Palabras del Decano
</h2>
<div class="consejodirectv">
    <img class="img" src="./assets/img/DECANO.png" alt="">
    <p>
        <b>Estimados colegas:</b>

        En primer lugar, deseamos agradecerles a todos ustedes por la confianza que depositaron en nosotros para asumir
        la función de Consejo Directivo en nuestro colegio, por los periodos 2020 - 2021.
    </p>
    <br>
    <p>
        El camino que hemos recorrido y los logros obtenidos como equipo hasta este momento, son el resultado de un
        propósito al cual nos comprometimos desde que anunciamos formalmente la decisión de postular al decanato de
        nuestra institución. Teniendo como visión: Convertir al Colegio de Contadores Públicos de La Libertad en una
        institución profesional de prestigio a nivel nacional e internacional cuyos miembros puedan ofrecer servicios
        profesionales de calidad basados en la ética profesional como signo distintivo del servicio logrando el
        reconocimiento de la sociedad y Estado. Además, nos comprometemos con la misión de velar por el progreso,
        prestigio, defensa y demás perrogativas de la profesión; procurar el perfeccionamiento profesional, la
        integración y bienestar familiar permitiendo la participación activa de sus miembros; prestar colaboración a los
        Poderes del Estado y profesiones organizadas; y promover relaciones con las universidades y otros centros de
        ciencias económicas y con las instituciones similares del Perú y del extranjero.
    </p>
    <br>
    <p>
        Iniciamos este nuevo año con muchos retos y dificultades que son parte de esta satisfactoria labor. Saber que
        estamos sirviendo a nuestra institución que en la actualidad alberga a más de 10,000 Contadores Públicos
        Colegiados y teniendo la certeza que contamos con el apoyo de todos ustedes nos permitirá seguir desarrollando
        el plan de trabajo que fue propuesto, asumiendo la gran responsabilidad de su actualización constante, enfocado
        a resaltar sus competencias profesionales de manera permanente brindando constantemente: cursos, capacitaciones
        y charlas gratuitas de manera presencial o virtual, tratando diferentes temas de interés.
        El excelente equipo que conforma este Consejo Directivo reafirmamos ante ustedes nuestro compromiso con el
        Colegio de Contadores Públicos de La Libertad, el principio que mantuvimos durante la candidatura y de la cual
        no nos hemos apartado en ningún instante y es el que marcará nuestro rumbo durante la gestión: “Un colegio para
        todos”, así poder lograr ser referentes a nivel nacional y distinguirnos como colegio profesional por contar con
        contadores de primer nivel, bien preparados y que sean identificados por su calidad y ética profesional.
    </p>
</div>
<img class="img" src="./assets/img/consejo.png" alt="">
<p style="text-align: center;margin-top:1rem">
    <b>¡Juntos haremos crecer nuestro Colegio de Contadores Públicos de La Libertad!</b>
    <br>
    Consejo Directivo
    <br>
    2020- 2021
</p>

<swal #show>
    <div class="modal" *swalPortal>
        <div class="perfil">
            <img [src]="'./assets/img/team/' + info.image" alt="">
            <div class="perfil-inf">
                <span>{{info.cargo}}</span>
                <p>{{info.nombre}}</p>
            </div>
        </div>
        <div class="cv">
            <ul>
                <span>FORMACIÓN ACADÉMICA:</span>
                <li *ngFor="let formacionacademica of info.formacionacademica">
                    {{formacionacademica}}
                </li>
            </ul>
            <ul>
                <span>ACTIVIDAD PROFESIONAL:</span>
                <li *ngFor="let actividadprofesional of info.actividadprofesional">
                    {{actividadprofesional}}
                </li>
            </ul>
        </div>
    </div>
</swal>