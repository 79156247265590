<h2 class="title">
    Beneficios Colegiados
</h2>
<div class="list-conv">
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio11.png" alt="">
            <article>
                <h1>Capacitaciones</h1>
                <p>El Colegio de Contadores de La Libertad ofrece a
                    sus agremiados diversas capaciones, para poder
                    promover la participación de los agremiados
                    con CCPLL.
                </p>
                <span [swal]="capacitacioneswal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio10.png" alt="">
            <article>
                <h1>Consultorio Contable</h1>
                <p>El Colegio de Contadores Públicos de la Libertad
                    pone a disposición de sus colegiados el servicio
                    de Consultoría Gratuita Especializada.
                </p>
                <span [swal]="consultoriowal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio12.png" alt="">
            <article>
                <h1>Biblioteca</h1>
                <p>El Colegio de Contadores Públicos de La Libertad
                    ofrece el servicio de biblioteca para los agremiados y público en general, contamos con una variedad
                    de
                    libros y con 03 computadoras donde
                    pueden consultar con la Revista Gaceta Jurídica.
                </p>
                <span [swal]="bibliotecawal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio3.png" alt="">
            <article>
                <h1>Bono de Capacitación</h1>
                <p>El Colegio de Contadores Públicos de La Libertad
                    pone a disposición el bono de capacitación por
                    el monto de S/ 96.00 soles, siempre y cuando el
                    contador pago todas las cuotas ordinarias de
                    enero a diciembre del presente año, plazo
                </p>
                <span [swal]="bonocapacitacionwal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio2.png" alt="">
            <article>
                <h1>Cámara de Comercio</h1>
                <p>El Colegio de Contadores de La Libertad ofrece a
                    sus agremiados diversas capaciones, para poder
                    promover la participación de los agremiados
                    con CCPLL.
                </p>
                <span [swal]="camaracomerciowal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio5.png" alt="">
            <article>
                <h1>Taller de Vida Sana</h1>
                <p>Es un beneficio para todos los contadores hábiles, es un taller donde se practica una vida saludable
                    para
                    poder superar el stress mediante la
                    práctica de ejercicios psicofísicos como: taichí,
                    relajación, automasaje, kinesioterapia, zumba y
                    caminatas.
                </p>
                <span [swal]="tallervidasanawal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio4.png" alt="">
            <article>
                <h1>Sauna y Masajes</h1>
                <p>Ingreso al sauna sin costo, exclusivo para contadores HABILES.
                </p>
                <span [swal]="saunamasajeswal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio6.png" alt="">
            <article>
                <h1>Gimnasio</h1>
                <p>El gimnasio es de uso exclusivo para contadores
                    hábiles, al momento de ingresar debe de sacar
                    su ticket en la maquina recaudadora.
                </p>
                <span [swal]="gimnasiowal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio7.png" alt="">
            <article>
                <h1>Aeróbicos</h1>
                <p>El taller de aeróbicos es de uso exclusivo para
                    contadores hábiles.
                </p>
                <span [swal]="aerobicoswal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio8.png" alt="">
            <article>
                <h1>Vacaciones Útiles</h1>
                <p>Los talleres de vacaciones útiles brindan una variedad de talleres exclusivo para los hijos, nietos y
                    sobrinos de contadores hábiles.
                    El taller de vacaciones útiles se da en los meses
                    de enero y febrero en el local central y en la Villa
                    del Contador.
                </p>
                <span [swal]="vacacionesutileswal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio9.png" alt="">
            <article>
                <h1>Peña Criolla del CCPLL</h1>
                <p>El Colegio de contadores Públicos de La Libertad brinda este taller para los contadores que les
                    gusta el canto y desean participar en diferentes
                    eventos que brinda la institución, como son el
                    día del padre, día de la madre, aniversario del
                    CCPLL, yunza, etc.
                </p>
                <span [swal]="criollawal">ver más >>></span>
            </article>
        </div>
    </div>
    <div class="item">
        <div class="card">
            <img src="./assets/img/beneficios/beneficio1.png" alt="">
            <article>
                <h1>Seguro Mutual</h1>
                <p>El Seguro Mutual es un fondo formado por el
                    aporte colectivo de los miembros colegiados,
                    en ejercicio e inscritos y será utilizado para los
                    beneficiarios de los miembros de la orden en
                    caso de defunción y destinado para otros bene-
                    ficios, solo se debe de cancelar la cuota de 10 soles por fallecido.
                </p>
                <span [swal]="seguromutualcard">ver más >>></span>
            </article>
        </div>
    </div>
</div>

<swal #capacitacioneswal>
    <div class="modal" *swalPortal>
        <div class='modal'><img src='./assets/img/beneficios/beneficio11.png' alt=''>
            <div class='content'>
                <h3 style='margin-bottom: 1rem;'>CAPACITACIONES</h3>
                <p>El Colegio de Contadores de La Libertad ofrece a sus agremiados diversas capaciones, para poder
                    promover la participación de los agremiados con CCPLL.</p><br>
                <p>Últimas capacitaciones:</p><br>
                <div>
                    <a href="https://ccpll.org/capacitacion/{{dataTemp[0].categoria}}/{{dataTemp[0].titulo}}">
                        <img [src]="'https://server.ccpll.org/public/images/'+dataTemp[0].categoria +'/' + dataTemp[0].imagen"
                            alt="">
                    </a>
                </div>
                <div>
                    <a href="https://ccpll.org/capacitacion/{{dataTemp[1].categoria}}/{{dataTemp[1].titulo}}">
                        <img [src]="'https://server.ccpll.org/public/images/'+dataTemp[1].categoria +'/' + dataTemp[1].imagen"
                            alt="">
                    </a>
                </div>
                <div>
                    <a href="https://ccpll.org/capacitacion/{{dataTemp[2].categoria}}/{{dataTemp[2].titulo}}">
                        <img [src]="'https://server.ccpll.org/public/images/'+dataTemp[2].categoria +'/' + dataTemp[2].imagen"
                            alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</swal>

<swal #consultoriowal html="
<div class='modal'>
<img src='./assets/img/beneficios/beneficio10.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>CONSULTORIO CONTABLE</h3>
        <p>El Colegio de Contadores Públicos de la Libertad pone a disposición de sus colegiados el servicio de Consultoría
        Gratuita Especializada.</p>
        <br>
        <div style='display:flex'>
            <div style='display:flex;flex-direction:column'>
                <span style='color: #14387f'>ESTA SEMANA</span>
                <div style='margin-bottom: 1rem'>
                    <span style='color: #14387f'>Jueves 12:</span>
                    <div style='display:flex'>
                        <div>
                            <img src='./assets/img/icon/icon2.png' alt='' style='width: 100px;'>
                        </div>
                        <p>CPC. Nelson Paredes Vásquez <br>Auditor y Consultor Contable – Docente en
                            la Universidad ABC</p>
                    </div>
                </div>
                <div style='margin-bottom: 1rem'>
                    <span style='color: #14387f'>Viernes 13:</span>
                    <div style='display:flex'>
                        <div>
                            <img src='./assets/img/icon/icon2.png' alt='' style='width: 100px;'>
                        </div>
                        <p>CPC. Israel Encarnación Soto <br>Consultor Contable Tributario – Contador
                            de la Empresa ABC</p>
                    </div>
                </div>
            </div>
            <div style='display:flex;flex-direction:column'>
                <div style='display:flex;flex-direction:row;margin-bottom: 1rem'>
                    <div>
                        <img src='./assets/img/icon/icon5.png' alt='' style='width: 60px;'>
                    </div>
                    <div style='text-align: left;'>
                        <span style='color: #14387f'>Horarios de Atención:</span>
                        <p>Lunes a viernes 7:00 - 9:00 pm <br> Confirmar Cita hasta las 6:00 pm</p>
                    </div>
                </div>
                <div style='display:flex;flex-direction:row;margin-bottom: 1rem'>
                    <div>
                        <img src='./assets/img/icon/icon6.png' alt='' style='width: 60px;'>
                    </div>
                    <div style='text-align: left;'>
                        <span style='color: #14387f'>Informes:</span>
                        <p>044 257353 - 245641 - 250204 <br>948315611</p>
                    </div>
                </div>
                <div style='display:flex;flex-direction:row;margin-bottom: 1rem'>
                    <div>
                        <img src='./assets/img/icon/icon4.png' alt='' style='width: 60px;'>
                    </div>
                    <div style='text-align: left;'>
                        <span style='color: #14387f'>Lugar:</span>
                        <p>Av. Jesús de Nazareth #247 Urb. San Andrés</p>
                    </div>
                </div>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>


<swal #bibliotecawal html="
<div class='modal'>
<img src='./assets/img/beneficios/beneficio12.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>BIBLIOTECA</h3>
        <p>El Colegio de Contadores Públicos de La Libertad ofrece el servicio de biblioteca para los agremiados y público
            en general, contamos con una variedad de libros y con 03 computadoras donde pueden consultar con la Revista
            Gaceta Jurídica.</p>
        <br>
        <span style='color: #14387f;margin-bottom:2rem;display:block;font-size: 20px;'>Horarios de Atención:</span>
        <br>
        <div style='display:flex;justify-content: space-around;'>
            <div style='display:flex;flex-direction:column'>
                <div style='display:flex;flex-direction:row;margin-bottom: 1rem'>
                    <div>
                        <img src='./assets/img/icon/icon5.png' alt='' style='width: 60px;'>
                    </div>
                    <div style='text-align: left;'>
                        <span style='color: #14387f'>Lunes a viernes:</span>
                        <p>9:00 a 1:00 p.m.  <br>4:00 p.m. a 8:00 p.m</p>
                        <br>
                        <span style='color: #14387f'>Sábados:</span>
                        <p>9:00 a 1:00 p.m.</p>
                    </div>
                </div>
            </div>
            <div style='display:flex;flex-direction:column'>
                <div style='display:flex;flex-direction:row;margin-bottom: 1rem'>
                    <div>
                        <img src='./assets/img/icon/icon6.png' alt='' style='width: 60px;'>
                    </div>
                    <div style='text-align: left;'>
                        <span style='color: #14387f'>Detalle de libros:</span>
                    </div>
                </div>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>

<swal #bonocapacitacionwal html="
<div class='modal'>
<img src='./assets/img/beneficios/beneficio3.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>BONO DE CAPACITACIÓN</h3>
        <p>El Colegio de Contadores Públicos de La Libertad pone a disposición el bono de capacitación por el monto de
            S/ 96.00 soles, siempre y cuando el contador pago todas las cuotas ordinarias de enero a diciembre del presente
            año, plazo máximo hasta el 29 de febrero de 2020, este bono es dado por acuerdo de Asamblea General Extraordinaria de fecha 06.10.2013 donde se establece en conformidad con el Art. 92-b) lo siguiente:</p>
        <br>
        <p>
            Las cuotas mensuales que deben abonar los miembros titulares, generándoles un bono gratuito de capacitación por un monto no menor al pago de las cuotas ordinarias, pudiendo utilizarse solamente en el ejercicio al
que correspondan las cuotas ordinarias. Los miembros vitalicios gozarán del 50% del Bono de Capacitación. 
        </p>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>

<swal #camaracomerciowal html="
<div class='modal'>
<img src='./assets/img/beneficios/beneficio2.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>CÁMARA DE COMERCIO</h3>
        <p>
            Los miembros del colegio de contadores, podrán acceder a descuentos en: seminarios, cursos especializados, programa de alta especialización, diplomados, entre otros, realizado por la Cámara de Comercio de La Libertad.
        </p>
        <br>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>

<swal #tallervidasanawal html="<div class='modal'><img src='./assets/img/beneficios/beneficio5.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>TALLER DE VIDA SANA</h3>
        <p>Es un beneficio para todos los contadores hábiles, es un taller donde se practica una vida saludable para poder
            superar el stress mediante la práctica de ejercicios psicofísicos como: taichí, relajación, automasaje, kinesioterapia, zumba y caminatas.
        </p>
        <br>
        <div>
            <div class='taller-div'>
                <span style='color: #14387f'>PROFESORA</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon7.png' alt=''></div>
                <p>CPC. María Santillán</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>HORARIO</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon5.png' alt=''></div>
                <p>Lunes y miércoles <br>
                    7:00 - 9:00 pm</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>INFORMES</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon6.png' alt=''></div>
                <p>044 257353 - 245641 <br>
                    - 250204 <br>
                    948315611</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>LUGAR</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon4.png' alt=''></div>
                <p>Av. Jesús de Nazareth #247 <br>
                    Urb. San Andrés</p>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>

<swal #saunamasajeswal html="<div class='modal'><img src='./assets/img/beneficios/beneficio4.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>SAUNA Y MASAJES</h3>
        <p>Ingreso al sauna sin costo, exclusivo para contadores HABILES</p>
        <p>Costo para particular y contadores no hábiles: S/ 15.00 soles</p>
        <p>Costo de masajes para contadores hábiles: S/ 5.00 soles por 15’ minutos.</p>
        <p>Particulares y contadores no hábiles: S/ 10.00 soles por 15’ minutos</p><br>
        <p>Nota: el horario de ingreso al sauna solo es hasta las 7:00 p.m</p><br><br>
        <div>
            <div class='taller-div'>
                <span style='color: #14387f'>TERAPISTA</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon7.png' alt=''></div>
                <p>CPC. Ana Carrasco <br>
                    Rodríguez</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>HORARIO</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon5.png' alt=''></div>
                <p>Turno damas <br>
                    Martes y viernes <br><br>
                    Turno varones <br>
                    Miércoles y sábado <br>
                    4:00 p.m. a 8:00 p.m
                </p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>INFORMES</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon6.png' alt=''></div>
                <p>044 257353 - 245641 <br>
                    - 250204 <br>
                    948315611</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>LUGAR</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon4.png' alt=''></div>
                <p>Av. Jesús de Nazareth #247 <br>
                    Urb. San Andrés</p>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>

<swal #gimnasiowal html="<div class='modal'><img src='./assets/img/beneficios/beneficio6.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>GIMNASIO</h3>
        <p>El gimnasio es de uso exclusivo para contadores hábiles, al momento de ingresar debe de sacar su ticket en la
            maquina recaudadora.</p><br><br>
        <div>
            <div class='taller-div'>
                <span style='color: #14387f'>DETALLE DE MÁQUINAS</span>
                <ul>
                    <li>Maquinas trotadoras.</li>
                    <li>Máquina para piernas, brazos.</li>
                    <li>Maquina vibradora para después del ejercicio.</li>
                    <li>Bicicleta estacionaria.</li>
                    <li>Elípticas.</li>
                    <li>Ab coaster.</li>
                    <li>Banco para pesas.</li>
                    <li>Pesas.</li>
                    <li>Saco de boxeo.</li>
                </ul>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>HORARIO</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon5.png' alt=''></div>
                <p>Lunes a viernes <br>
                    4:00 p.m. a 8:00 p.m.
                    <br><br>
                    sábados <br>
                    4:00 p.m. a 7:00 p.m.
                </p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>INFORMES</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon6.png' alt=''></div>
                <p>044 257353 - 245641 <br>
                    - 250204 <br>
                    948315611</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>LUGAR</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon4.png' alt=''></div>
                <p>Av. Jesús de Nazareth #247 <br>
                    Urb. San Andrés</p>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>
<swal #aerobicoswal html="<div class='modal'><img src='./assets/img/beneficios/beneficio7.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>AERÓBICOS</h3>
        <p>El taller de aeróbicos es de uso exclusivo para contadores hábiles.</p><br><br>
        <div>
            <div class='taller-div'>
                <span style='color: #14387f'>PROFESOR</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon2.png' alt=''></div>
                <p>Rodrigo Honores Morales</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>HORARIO</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon5.png' alt=''></div>
                <p>Martes y jueves<br>
                    7:00 p.m. a 8:00 p.m
                </p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>INFORMES</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon6.png' alt=''></div>
                <p>044 257353 - 245641 <br>
                    - 250204 <br>
                    948315611</p>
            </div>
            <div class='taller-div'>
                <span style='color: #14387f'>LUGAR</span>
                <div style='margin: 1rem 0;'><img src='./assets/img/icon/icon4.png' alt=''></div>
                <p>Av. Jesús de Nazareth #247 <br>
                    Urb. San Andrés</p>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>
<swal #vacacionesutileswal html="<div class='modal'><img src='./assets/img/beneficios/beneficio8.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>VACACIONES ÚTILES</h3>
        <p>Los talleres de vacaciones útiles brindan una variedad de talleres exclusivo para los hijos, nietos y sobrinos de
            contadores hábiles.</p>
            <p>
                El taller de vacaciones útiles se da en los meses de enero y febrero en el local central y en la Villa del Contador.
            </p>
        <div>
            <div class='vac-div1'>
                <span style='color: #14387f'>REQUISITOS</span>
                <ul>
                    <li>Pago de cuota del mes enero
                        del presente año.</li>
                    <li>Máximo de 02 talleres por niño.</li>
                    <li>Presentar el DNI del menor al
                        momento de la inscripción</li>
                </ul>
            </div>
            <div class='vac-div2'>
                <span style='color: #14387f'>TALLERES</span>
                <ul style='column-count:2;'>
                    <li>Ajedrez</li>
                    <li>Computación</li>
                    <li>Marinera</li>
                    <li>Marinera kids</li>
                    <li>Danzas peruanas</li>
                    <li>Karate</li>
                    <li>Fútbol</li>
                    <li>Dibujo y pintura</li>
                    <li>Pelta de frontón</li>
                    <li>Manualidades</li>
                    <li>Tenis de mesa</li>
                    <li>Baile moderno</li>
                    <li>Natación</li>
                    <li>Vóley</li>
                    <li>Aeróbicos</li>
                    <li>Básquet</li>
                    <li>Canto</li>
                </ul>
            </div>
            <div class='vac-div3'>
                <div style='margin: 1rem 0;'>
                    <div style='display: flex;'><img src='./assets/img/icon/icon6.png' alt=''>
                        <span style='color: #14387f'>INFORMES</span>
                    </div>
                        <p style='text-align:center'>044 257353 - 245641 <br>
                            - 250204 <br>
                            948315611</p>
                </div>
                <div style='margin: 1rem 0;'>
                    <div style='display: flex;'>
                        <img src='./assets/img/icon/icon4.png' alt=''>
                        <span style='color: #14387f'>LUGAR</span>
                    </div>
                        <p style='text-align:center'>Av. Jesús de Nazareth #247 <br>
                            Urb. San Andrés</p>
                </div >
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>
<swal #criollawal html="<div class='modal'><img src='./assets/img/beneficios/beneficio9.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>PEÑA CRIOLLA DEL CCPLL</h3>
        <p>El Colegio de contadores Públicos de La Libertad brinda este taller para los contadores que les gusta el canto y
            desean participar en diferentes eventos que brinda la institución, como son el día del padre, día de la madre,
            aniversario del CCPLL, yunza, etc.</p>
        <div>
            <div class='vac-div1'>
                <span style='color: #14387f'>DIRECTOR:</span>
                <ul>
                    <li>CPC. Cortijo Leiva Orlando
                        David – 02-4414</li>
                </ul>
                <span style='color: #14387f'>COORDINADOR:</span>
                <ul>
                    <li>CPC. Cantos Villavicencio
                        Oswaldo – 02-1106</li>
                </ul>
                <span style='color: #14387f'>REQUISITOS:</span>
                <ul>
                    <li>Miembro colegiado de la orden</li>
                    <li>Habilidad musical en nivel intermedio o avanzado</li>
                    <li>Ejecución de instrumentos de
                        cuerda, percusión, teclado, y/o
                        saxo</li>
                </ul>
            </div>
            <div class='vac-div2'>
                <span style='color: #14387f'>INTEGRANTES</span>
                <ul>
                    <li>CPC. Mantilla López Lenin Alejandro – 02-6351</li>
                    <li>CPC. Vidal Heras Luis Alberto – 02-4711</li>
                    <li>CPC. Barranzuela Vásquez Aracely Anabella del
                        Rosario – 02-8490</li>
                    <li>CPC. Rojas Paredes María Teresa – 02-1116</li>
                    <li>CPC. Alva Yépez Wendy Julissa – 02-8846</li>
                    <li>CPC. Terrones Avalos Yoana Enith – 02-4463</li>
                    <li>CPC. Paredes Gutiérrez Luis Alberto – 02-2780</li>
                    <li>CPC. Palomino Pastor Rodolfo Alberto – 02-1206</li>
                    <li>CPC. Estrada Albrecht Carlos Ernesto – 02-3301</li>
                </ul>
            </div>
            <div class='vac-div3'>
                <div style='margin: 1rem 0;'>
                    <div style='display: flex;'><img src='./assets/img/icon/icon5.png' alt=''>
                        <span style='color: #14387f'>HORARIO</span>
                    </div>
                        <p style='text-align:center'>Lunes y miércoles <br>
                            8:00 p.m. a 10:00 p.m</p>
                </div>
                <div style='margin: 1rem 0;'>
                    <div style='display: flex;'><img src='./assets/img/icon/icon6.png' alt=''>
                        <span style='color: #14387f'>INFORMES</span>
                    </div>
                        <p style='text-align:center'>044 257353 - 245641 <br>
                            - 250204 <br>
                            948315611</p>
                </div>
                <div style='margin: 1rem 0;'>
                    <div style='display: flex;'>
                        <img src='./assets/img/icon/icon4.png' alt=''>
                        <span style='color: #14387f'>LUGAR</span>
                    </div>
                        <p style='text-align:center'>Av. Jesús de Nazareth #247 <br>
                            Urb. San Andrés</p>
                </div >
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>
<swal #seguromutualcard html="<div class='modal'><img src='./assets/img/beneficios/beneficio1.png' alt=''>
    <div class='content'>
        <h3 style='margin-bottom: 1rem;'>SEGURO MUTUAL</h3>
        <p>El Seguro Mutual es un fondo formado por el aporte colectivo de los miembros colegiados, en ejercicio e inscritos y será utilizado para los beneficiarios de los miembros de la orden en caso de defunción y destinado para
            otros beneficios, solo se debe de cancelar la cuota de 10 soles por fallecido.</p>
            <p>Ejemplo de cómo se genera la cuota del seguro mutual:</p>
            <p>Si en el mes de enero fallecen 3 contadores afiliados al seguro, el contador debe de cancelar por cada uno de
            ellos el monto de S/ 10.00 soles, es decir, que debe de pagar 30 soles, el cobro se hace en base a la cantidad de
            fallecidos que se tenga en el mes., esto es un ejemplo referencial, porque al año tenemos en promedio entre 8
            a 10 fallecidos y no fallecen todos los meses.
            </p><br>
        <div>
            <div class='seg-div1'>
                <span style='color: #14387f'>BENEFICIO</span>
                <ul>
                    <li>Pueden atenderse en los laboratorios de Bermanlab o Escalabs
                        completamente gratis en algunos análisis clínicos a nivel nacional. *</li>
                    <li>Adelanto de S/ 1500.00 soles *</li>
                    <li>En caso de fallecimiento del miembro de la orden, los beneficiarios
                        reciben el monto de S/ 15000.00 *</li>
                </ul>
                <br>
                <p>*(revisar las condiciones en el Reglamento del Seguro Mutual)</p>
            </div>
            <div class='seg-div2'>
                <span style='color: #14387f'>MÁS INFORMACIÓN:</span>
                <ul>
                    <li>Relación de Asegurados.</li>
                    <li>Ingresos y egresos</li>
                    <li>Pagos de seguro mutual.</li>
                    <li>Adelantos de Seguro Mutual</li>
                    <li>Analisis clínicos</li>
                </ul>
            </div>
        </div>
    </div></div>" [showCancelButton]="false" [focusCancel]="false" confirmButtonText="ver menos">
</swal>