<footer>
    <div class="container">
        <div class="container-footer">
            <div class="logo">
                <a routerLink="/">
                    <img src="./assets/img/logo.png" alt="">
                </a>
            </div>
            <ul class="contact">
                <span class="title">Contacto</span>
                <li>
                    <p>Dirección:</p>
                    <p>Av. Jesús de Nazareth N° 247 -<br> Urb. San Andrés</p>
                </li>
                <li>
                    <p>Teléfono:</p>
                    <p>(044)-257353</p>
                    <p>(044)-245641</p>
                    <p>(044)-250204</p>
                </li>
                <li>
                    <p>Celular:</p>
                    <p>+51 948315611</p>
                </li>
                <li>
                    <p>Correo Electrónico:</p>
                    <p>informes@ccpll.org</p>
                    <p>secretaria@ccpll.org</p>
                    <p>tesoreria@ccpll.org</p>
                </li>
            </ul>
            <ul class="schedule">
                <span class="title">Horario de Atención</span>
                <li style="margin-bottom: 1rem;">
                    <p>Lunes a Viernes</p>
                    <p>9:00 a.m. - 1:00 p.m.</p>
                    <p>4:00 p.m. - 8:00 p.m.</p>
                </li>
                <li>
                    <p>Sábados</p>
                    <p>9:00 a.m. - 1:00 p.m.</p>
                </li>
                <div class="social-network">
                    <a href="https://www.facebook.com/ColegioContadoresLaLibertad/" target="_blank"><span><i
                                class="fab fa-facebook-f"></i></span></a>
                    <a href="https://www.instagram.com/colegiodecontadores.lalibertad/" target="_blank"><span><i
                                class="fab fa-instagram"></i></span></a>
                    <a href="https://twitter.com/CCPLL1?s=20" target="_blank"><span><i
                                class="fab fa-twitter"></i></span></a>
                    <a href="https://www.linkedin.com/in/colegio-contadores-b113a01a6/" target="_blank"><span><i
                                class="fab fa-linkedin-in"></i></span></a>
                    <a href="https://www.youtube.com/user/MultiContadores" target="_blank"><span><i
                                class="fab fa-youtube"></i></span></a>
                    <a href="https://twitter.com/CCPLL1?s=20" target="_blank"><span><i
                                class="fab fa-whatsapp"></i></span></a>
                </div>
            </ul>
        </div>
    </div>
</footer>