<h2 class="title">
    Informes
</h2>
<div class="mesapartes">
    <p>PARA MÁS INFORMACIÓN ESCRÍBENOS AL SIGUIENTE CORREO:</p>
    <br>
    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=capacitaciones@ccpll.org"
        target="_blank">capacitaciones@ccpll.org</a>
    <br>
    <br>
    <p>O COMUNÍCATE AL:</p>
    <br>
    <a><i class="fas fa-phone"></i> 948 315 611</a>
    <p>Alberto Moreno Fuentes</p>
    <span>Promotor de Servicios</span>
</div>