<h2 class="title">
    Formas de Pago
</h2>
<div class="list-conv">
    <div class="convenio deps">
        <div class="descrip">
            <span class="title">DEPÓSITO O TRANSFERENCIA</span>
            <ul>
                <li>
                    <p>
                        A NOMBRE DE: <br>
                        <b>COLEGIO DE CONTADORES PÚBLICOS DE LA LIBERTAD</b><br>
                    </p>
                </li>
                <li>
                    <img src="./assets/img/bbva.png">
                    <p class="import">
                        Cta. Cte. Nº 0011-0249-0100002625<br>
                        C.C.I.  Nº 011-249-000100002625-00
                    </p>
                </li>
            </ul>
            <p>Remitir el voucher de depósito vía transferencia escaneado al correo electrónico:</p>
            <div class="email">
                <img src="./assets/img/icon/icon.png" alt="" style="width: 20px; margin-right: 5px">
                <p class="import">
                    tesoreria@ccpll.org
                </p>
            </div>
            <p>Indicar sus datos personales</p>
        </div>
    </div>
    <div class="convenio canal">
        <div class="descrip">
            <span class="title">CANALES DE ATENCIÓN</span>
            <span style="display: flex;align-items: center;justify-content: center;color:#274788"><img
                    src="./assets/img/icon/icon.png" alt="" style="width: 20px; margin-right: 5px;">CORREO ELECTRÓNICO:
            </span>
            <p class="import">tesoreria@ccpll.org</p>
            <p class="import">secretaria@ccpll.org</p>
            <p class="import">informes@ccpll.org</p>
            <div class="contact-phone">
                <div class="info-item phone">
                    <span>Teléfonos:</span>
                    <div class="lst-t">
                        <img src="./assets/img/icon/icon3.png" alt="">
                        <div>
                            <p>044-257353 </p>
                            <p>044-245641</p>
                            <p>044-250204</p>
                        </div>
                    </div>
                </div>
                <div class="info-item cell">
                    <span>Celular:</span>
                    <div class="lst-t">
                        <img src="./assets/img/icon/icon1.png" alt="">
                        <p>948315611</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="convenio">
        <div class="descrip">
            <span class="title">TESORERÍA DEL COLEGIO</span>
            <div class="schedule-main">
                <ul class="schedule">
                    <span>Horario de Atención:</span>
                    <li>
                        <span>
                            <img src="./assets/img/icon/icon5.png" alt="">
                            Lunes a Viernes</span>
                        <p>09:00 - 13:00</p>
                        <p>16:00 - 20:00</p>
                    </li>
                    <li>
                        <span><img src="./assets/img/icon/icon5.png" alt=""> Sábados</span>
                        <p>09:00 - 13:00</p>
                    </li>
                </ul>
            </div>
            <span> <img src="./assets/img/icon/icon4.png" alt="">Dirección:</span>
            <p>Av. Jesús de Nazareth N° 247 Urb. San Andrés </p>
        </div>
    </div>
</div>
<div class="cards">
    <span>Para pagos en nuestras oficinas aceptamos las siguientes tarjetas de crédito:</span>
    <div class="list-cards">
        <img src="./assets/img/dinersclub.png">
        <img src="./assets/img/americanexpress.png">
        <img src="./assets/img/visa.png">
    </div>
</div>