import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicados',
  templateUrl: './duplicados.component.html',
  styleUrls: ['./duplicados.component.css']
})
export class DuplicadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
