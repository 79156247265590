<h2 class="title">
    Historial estado de cuenta
</h2>
<div>
    <ul class="responsive-table">
        <li class="table-header">
            <div class="col col-1">
                <p>Servicio</p>
            </div>
            <div class="col col-1">
                <p>Fecha</p>
            </div>
            <div class="col col-1">
                <p>Importe</p>
            </div>
            <div class="col col-1">

            </div>
        </li>
        <li class="table-row" *ngFor="let item of datahistorial  | paginate: { itemsPerPage: 10, currentPage: p }">
            <div class="col col-1" data-label="Servicio:">{{item._DESCRIPCION}}</div>
            <div class="col col-1" data-label="Fecha:">{{item._FECHA}}</div>
            <div class="col col-1" data-label="Importe:">S/ {{item._IMPORTE}}</div>
            <div class="col col-1"><i class="far fa-eye" routerLink="/usuario/detalle-historial-estado-cuenta/{{item._PRODUCTO_ID}}"></i></div>
        </li>
    </ul>
    <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
</div>
<div class="loader-demo-box" *ngIf="preloadingstate">
    <div class="circle-loader"></div>
</div>