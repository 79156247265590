<div class="misvis">
    <div>
        <img src="./assets/img/misionvision.png" alt="">
    </div>
    <div>
        <div class="mision">
            <h2 class="title">
                Misión
            </h2>
            <p>«Colegio que representa a los profesionales contables de La Libertad, que promueve, el desarrollo y
                competencia profesional; garantizando el ejercicio profesional, con ética, calidad y competitividad en
                beneficio del interés público».</p>
        </div>
        <div class="vision">
            <h2 class="title">
                Visión
            </h2>
            <p>«Al 2025; seremos reconocidos, como el colegio profesional que gestiona con los más altos estándares de
                ética, calidad profesional y estrategia digital, con agremiados especializados, con excelente desempeño,
                que contribuyen al desarrollo de la Región y el País».</p>
        </div>
    </div>
</div>
<div class="historia">
    <h2 class="title">
        Historia
    </h2>
    <p>
        Hasta inicios de 1938 no existían contadores de extracción universitaria formados en el Perú, el 03 de Setiembre
        de 1938 la Universidad Nacional de La Libertad (hoy UNT) entrega a Luis Burga Farro el primer título de Contador
        Público en el Perú y es que, en 1935 se había creado en esa Primera Universidad Republicana la Escuela Superior
        de Ciencias Comerciales, pocos años más tarde, la Universidad San Marcos y Católica de Lima, San Antonio del
        Cuzco y San Agustín de Arequipa iniciarían también la formación de Contadores Públicos.
        <br><br>
        <img src="./assets/img/historia1.png">
        Un 17 de Julio de 1942, reunidos en la ciudad de Lima, en el local de la Sociedad de Ingenieros del Perú, 34
        Contadores firman el acta de Fundación del Colegio de Contadores Públicos del Perú y nombran su primera
        directiva que presidió el CP Marcial García Pantoja; el 27 de Noviembre de 1952 se forma la Filial Cuzco del
        Colegio de Contadores del Perú presidiendo la primera directiva el CP Antonio Callo Cáceres; el 12 de Octubre de
        1956 se forma la Filial Arequipa cuya junta directiva presidió el CP Alejandro Tejada Rodríguez y, en Junio de
        1957 se forma la Filial La Libertad cuya directiva fue presidida por el CP Lucio Flores Sabogal.
        <br><br>
        En vísperas de la clausura de la Primera Convención de Contadores Públicos del Perú, un 11 de Setiembre de 1959,
        siendo Presidente de la República el Sr. Manuel Prado Ugarteche, se promulga la Ley 13253 Ley de
        Profesionalización del Contador Público que venía siendo reclamada desde una década atrás, de esta forma, la
        Filial La Libertad del Colegio de Contadores Públicos del Perú dá lugar al Colegio de Contadores Públicos de La
        Libertad ratificándose la misma directiva presidida por el CPC Lucio Flores Sabogal e integrada por los CPC
        Ramón Morales Moreno, Juan Cisneros Navarrete, Miguel Moreno Loyola, Eduardo Monsalve Morante, Andrés Fukunaga
        Soyama, Jesús Medina Alva, Víctor Aguilar Roncal, Francisco Aguirre Morales, Augusto Zapata Palacios y Yolanda
        Bladwin de González Villaverde.
        <br><br>
        Los siguientes 20 años a la formación del Colegio los esfuerzos de los contadores públicos de la Libertad
        estuvieron dedicados a construir el local institucional sobre el terreno entregado por la Municipalidad de
        Trujillo, de esta forma a partir de aportes extraordinarios, donaciones, rifas y otras actividades vimos como
        cobraban vida las bases, el sótano, el primer piso, el segundo y el tercero; debemos reconocer en esta época el
        intenso trabajo de decenas de colegas que tras el liderazgo de los Decanos Andrés Fukunaga Soyama (61-62),
        Eduardo Monsalve Morante (62-65), Jesús Medina Alva (65-66), Eduardo Monsalve Morante (66-68), Jorge Saito
        Calderón (69-73), Andrés Fukunaga Soyama (74-77) y Javier Córdova Córdova (78-79) dieron una extraordinaria
        cuota de trabajo para tener el local que ahora nos alberga.
        <br><br>
        <img src="./assets/img/historia2.png" alt="" width="450" style="float: right;">
        La mala conducción política del país en los años 80 que terminó destrozando la economía y precipitando el
        surgimiento de un terrorismo demencial que azotó al país hasta entrado 1995, nos llevó a una crisis que golpeó
        no solo al país sino también a la profesión contable peruana, en esas circunstancias aparece la figura de un
        extraordinario miembro del Colegio de La Libertad el CPC Ramón Chumán Rojas (Decano 88-89) quién en
        representación de la Cámara de Comercio y Producción de La Libertad y CONFECAMARAS se integra en 1990 al primer
        Consejo Normativo de Contabilidad que asumió la tarea de restituir la utilidad de los Estados Financieros para
        la toma de decisiones produciendo para ello las normas técnicas para la Reexpresión de los Estados Financieros
        para Corregir los Efectos de la Inflación (Resoluciones CNC Nº 1, 2 y 3), en 1992 asume la Presidencia de la
        Federación de Colegios de Contadores Públicos del Perú dónde se da a la tarea de reinsertar a la profesión
        contable peruana en la Federación Internacional de Contabilidad IFAC, gremio mundial del que había sido retirada
        luego de cesar sus pagos de membresía por más de cinco años, la cancelación efectuada (US$ 25,000 permitió el
        retorno de la profesión peruana a la vida institucional mundial y para esta ocasión el CPA Jhon Gruner Director
        General de IFAC vino a Perú a formalizarlo en Abril de 1993.
        <br><br>
        El Colegio de Contadores Públicos de la Libertad ha cumplido siempre un rol protagónico y muchas veces
        determinante en el desarrollo de la Ciencia Contable y la Institucionalidad en el País y hoy junto a un numeroso
        contingente de Contadores Públicos de los principales Colegios de Contadores Públicos Departamentales se halla
        desde hace varios años en primera línea luchando contra los flagelos que amenazan el futuro de la Nación: la
        corrupción y la pérdida de la institucionalidad; con este propósito y hoy contando con más de 10,000 colegiados
        el Consejo Directivo viene trabajando en la reforma estatutaria, la formulación del Plan Estratégico
        Institucional 2020 – 2025, la sostenibilidad económica de sus actividades, la consolidación de los Comités
        Técnico – Funcionales, la recuperación del liderazgo nacional, el establecimiento de la plataforma virtual para
        educación a distancia y la restructuración de sus actividades para superar los efectos de la pandemia global.
    </p>
</div>