<h2 class="title">
    DUPLICADO DE CARNÉ
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/duplicado de carné.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>COSTO S/. 25.00, PAGAR DERECHO EN TESORERÍA.</li>
            <li>01 FOTOGRAFÍA A COLOR ACTUALIZADA.</li>
            <li>LA ENTREGA DEL NUEVO CARNET ES INMEDIATA.</li>
        </ul>
    </div>
</div>
<h2 class="title">
    DUPLICADO DE PÓLIZA
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/duplicado de poliza.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>COSTO S/. 30.00, PAGAR DERECHO EN TESORERÍA.</li>
        </ul>
    </div>
</div>
<h2 class="title">
    DUPLICADO DE DIPLOMA DE COLEGIATURA
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/duplicad de diploma.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>COSTO S/. 25.00, PAGAR DERECHO EN TESORERÍA.</li>
            <li>01 FOTOGRAFÍA TAMAÑO PASAPORTE A COLOR ACTUALIZADA.</li>
            <li>LA ENTREGA ES INMEDIATA.</li>
        </ul>
    </div>
</div>