<h2 class="title">
    Inscripción de Sociedades de Auditoría
</h2>
<div class="main">
    <span class="subtitle">REQUISITOS PARA COLEGIARSE COMO SOCIEDAD DE AUDITORÍA</span>
    <ul>
        <li>SOLICITUD DE INSCRIPCIÓN DIRIGIDA AL SR. DECANO</li>
        <li>ADJUNTAR A LA SOLICITUD LOS SIGUIENTES DOCUMENTOS:</li>
        <ul>
            <li>- Ficha RUC de la Sociedad.</li>
            <li>- Declaración Jurada de los socios de no pertenecer a otra Sociedad Auditora.</li>
            <li>- Copia de Escritura Pública de Constitución de la Sociedad y modificatorias, de corresponder.</li>
            <li>- Constancia de Habilidad Profesional de los Socios, emitida por el Colegio de Contadores Públicos de La
                Libertad. (S/. 10.00 soles).</li>
            <li>- Títulos Profesionales de Contador Público de cada uno de los socios.</li>
            <li>- Currículum Vitae de los socios. </li>
            <li>- Pago de derecho de Inscripción de S/ 1000.00</li>
        </ul>
        <li>Los socios deben ser miembros titulares del Colegio de Contadores Publicos de La Libertad y contar con una
            experiencia mínima de 3 años en el ejercicio de la profesional.</li>
    </ul>
</div>