import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-constancia-de-habilidad',
  templateUrl: './constancia-de-habilidad.component.html',
  styleUrls: ['./constancia-de-habilidad.component.css']
})
export class ConstanciaDeHabilidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
