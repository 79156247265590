import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seguro-mutual-tr',
  templateUrl: './seguro-mutual-tr.component.html',
  styleUrls: ['./seguro-mutual-tr.component.css']
})
export class SeguroMutualTrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
