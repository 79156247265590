import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colegiaturas',
  templateUrl: './colegiaturas.component.html',
  styleUrls: ['./colegiaturas.component.css']
})
export class ColegiaturasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
