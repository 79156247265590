import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscripcion-de-sociedades-de-audtoria',
  templateUrl: './inscripcion-de-sociedades-de-audtoria.component.html',
  styleUrls: ['./inscripcion-de-sociedades-de-audtoria.component.css']
})
export class InscripcionDeSociedadesDeAudtoriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
