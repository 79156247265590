<h2 class="title">
    Seguro Mutual
</h2>
<p class="subtitle">
    El Seguro Mutual es un derecho que tienen los Contadores al momento de la inscripción para colegiarse. Es de
    afiliación voluntaria para aquellos que no superen los 40 años de edad. Los requisitos son:
</p>
<div class="main">
    <div>
        <img src="./assets/img/icons/seguro mutual.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>LLENADO DE DECLARACIÓN JURADA DE AFILIACIÓN AL
                SEGURO MUTUAL.</li>
            <li>LLENADO DE PÓLIZA DE SEGURO MUTUAL.</li>
            <li>COPIA DE DNI DE SUS BENEFICIARIOS.</li>
        </ul>
    </div>
</div>
<h2 class="title">
    CAMBIO DE BENEFICIARIOS DEL SEGURO MUTUAL
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/cambio seguro mutual.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>SOLICITUD DEL TITULAR DE LA PÓLIZA DIRIGIDA AL DECANO.</li>
            <li>PÓLIZA ORIGINAL.</li>
            <li>COPIA DE DNI DEL TITULAR DE LA PÓLIZA.</li>
            <li>COPIA DE DNI DE LOS NUEVOS BENEFICIARIOS.</li>
            <li>COSTO S/. 10.00, DERECHO TRÁMITE.</li>
        </ul>
    </div>
</div>
<h2 class="title">
    RENUNCIA AL SEGURO MUTUAL
</h2>
<div class="main">
    <div>
        <img src="./assets/img/icons/renuncia seguro mutual.png" alt=""></div>
    <div>
        <span class="subtitle-req">REQUISITOS</span>
        <ul>
            <li>SOLICITUD DEL TITULAR DE LA PÓLIZA DIRIGIDA AL DECANO.</li>
            <li>PÓLIZA ORIGINAL (DEVOLUCIÓN).</li>
            <li>COPIA DE DNI DEL TITULAR DE LA PÓLIZA.</li>
        </ul>
    </div>
</div>