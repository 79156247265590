<h2 class="title">
    Normas Institucionales
</h2>
<div class="band">
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon1.png" alt="">
                <h1>Constitución Política del Perú</h1>
            </div>
        </article>
        <a href="./assets/archives/CONSTITUCION POLITICA DEL PERU.pdf"
            download="CONSTITUCIÓN POLÍTICA DEL PERÚ"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon2.png" alt="">
                <h1>Ley 28951</h1>
            </div>
        </article>
        <a href="./assets/archives/LEY NUMERO 28951.pdf" download="LEY Nº 28951"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon3.png" alt="">
                <h1>Estatuto</h1>
            </div>
        </article>
        <a href="./assets/archives/ESTATUTO DEL CCPLL.pdf" download="ESTATUTO DEL CCPLL"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon4.png" alt="">
                <h1>Proyecto de Reglamento Interno del Colegio de Contadores Públicos de La Libertad</h1>
            </div>
        </article>
        <a href="./assets/archives/PROYECTO DEL REGLAMENTO INTERNO DEL COLEGIO DE CONTADORES PÚBLICOS DE LA LIBERTAD.pdf"
            download="PROYECTO DEL REGLAMENTO INTERNO DEL COLEGIO DE CONTADORES PÚBLICOS DE LA LIBERTAD"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon5.png" alt="">
                <h1>Código de Ética del Contador Público Peruano</h1>
            </div>
        </article>
        <a href="./assets/archives/CODIGO DE ETICA DEL CONTADOR PERUANO.pdf"
            download="CÓDIGO DE ÉTICA DEL CONTADOR PERUANO"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon6.png" alt="">
                <h1>Código de Ética Profesional del Colegio</h1>
            </div>
        </article>
        <a href="./assets/archives/CODIGO DE ETICA DEL CCPLL.pdf"
            download="CÓDIGO DE ÉTICA DEL CCPLL"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon7.png" alt="">
                <h1>Reglamento Interno de Trabajo</h1>
            </div>
        </article>
        <a href="./assets/archives/REGLAMENTO INTERNO DE TRABAJO.pdf"
            download="REGLAMENTO INTERNO DE TRABAJO"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon8.png" alt="">
                <h1>Reglamento del Seguro Mutual</h1>
            </div>
        </article>
        <a href="./assets/archives/REGLAMENTO DEL SEGURO MUTUAL.pdf"
            download="REGLAMENTO DEL SEGURO MUTUAL"><span>Descargar</span></a>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon10.png" alt="">
                <h1>PROYECTO REGLAMENTO DE PERITOS DEL COLEGIO DE CONTADORES PÚBLICOS DE LA LIBERTAD</h1>
            </div>
        </article>
        <a href="./assets/archives/PROYECTO REGLAMENTO DEL COMITÉ DE PERITOS.pdf"
            download="PROYECTO REGLAMENTO DE PERITOS DEL COLEGIO DE CONTADORES PÚBLICOS DE LA LIBERTAD"><span>Descargar</span></a>
    </div>

    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon4.png" alt="">
                <h1>REGLAMENTO DE CERTIFICACION Y RECERTIFICACION -JDCCPP 2020</h1>
            </div>
        </article>
        <a href="./assets/archives/REGLAMENTO DE CERTIFICACION Y RECERTIFICACION -JDCCPP 2020.pdf"
            download="REGLAMENTO DE CERTIFICACION Y RECERTIFICACION -JDCCPP 2020"><span>Descargar</span></a>
    </div>

    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon4.png" alt="">
                <h1>REGLAMENTO DE REGISTRO DE AUDITORES INDEPENDIENTES</h1>
            </div>
        </article>
        <a href="./assets/archives/REGLAMENTO DE REGISTRO DE AUDITORES INDEPENDIENTES .pdf"
            download="REGLAMENTO DE REGISTRO DE AUDITORES INDEPENDIENTES"><span>Descargar</span></a>
    </div>

    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon4.png" alt="">
                <h1>Reglamento general de elecciones</h1>
            </div>
        </article>
        <a href="./assets/archives/REGLAMENTO GENERAL DE ELECCIONES.pdf"
            download="REGLAMENTO GENERAL DE ELECCIONES"><span>Descargar</span></a>
    </div>

    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon8.png" alt="">
                <h1>PROYECTO DE MODIFICACIÓN DEL SEGURO MUTUAL</h1>
            </div>
        </article>
        <a href="./assets/archives/PROYECTO DE MODIFICACIÓN DEL SEGURO MUTUAL.pdf"
            download="PROYECTO DE MODIFICACIÓN DEL SEGURO MUTUAL"><span>Descargar</span></a>
    </div>

    <div class="item">
        <article class="card">
            <div>
                <img src="./assets/img/icons/icon4.png" alt="">
                <h1>PROYECTO REGLAMENTO DE AUDITOR INDEPENDIENTE DEL COLEGIO DE CONTADORES PUBLICOS DE LA LIBERTAD
                </h1>
            </div>
        </article>
        <a href="./assets/archives/PROYECTO REGLAMENTO DEL REGISTRO DE AUDITORES INDEPENDIENTES CCPLL.pdf"
            download="PROYECTO DE REGLAMENTO DE AUDITOR INDEPENDIENTE DEL COLEGIO DE CONTADORES PUBLICOS DE LA LIBERTAD"><span>Descargar</span></a>
    </div>
</div>