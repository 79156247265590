<h2 class="title">
    Cuota Ordinaria
</h2>

<div class="card">
    <article>
        <img src="./assets/img/cuota.png" alt="">
        <p>LA CUOTA ORDINARIA ES EL APORTE MENSUAL QUE DEBEN
            ABONAR LOS MIEMBROS TITULARES PARA EL EJERCICIO DE LA PROFESIÓN DEBIENDO ESTAR AL DÍA EN SUS PAGOS. SIEMPRE
            QUE
            PAGUEN POR ADELANTADO DE TODO EL AÑO LES GENERA UN
            BONO GRATUITO DE CAPACITACIÓN POR UN MONTO NO MENOR AL
            PAGO DE LAS CUOTAS ORDINARIAS, PUDIENDO UTILIZARSE SOLAMENTE EN EL PERIODO AL QUE CORRESPONDA.</p>
    </article>
</div>