import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuota-ordinaria',
  templateUrl: './cuota-ordinaria.component.html',
  styleUrls: ['./cuota-ordinaria.component.css']
})
export class CuotaOrdinariaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
