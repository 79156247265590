<h2 class="title">
    Transparencia
</h2>
<p class="subtitle">
    El Portal de Transparencia del Colegio de Contadores Públicos de La Libertad es un área informativa de acceso libre que permite a cualquier usuario, en tiempo real, obtener la más completa información compuesta por bases de datos de la institución.
</p>
<p class="subtitle">
    En esta área usted podrá descargar:
</p>
<div class="list-trans">
    <ul>
        <li>
            <p>
                <a href="https://drive.google.com/drive/u/1/folders/0B-uD99Frd9XcMWdJVWk5OFpLTzg" target="_blank">ESTADOS FINANCIEROS
                    POR AÑO</a>
            </p>
        </li>
        <li>
            <p><a href="https://drive.google.com/drive/folders/0B-uD99Frd9XceUpRZ1Y5UHJhRzA" target="_blank">INGRESOS Y
                    EGRESOS MENSUALES Y ANUALES</a></p>
        </li>
        <li>
            <p><a href="https://drive.google.com/drive/u/1/folders/0B-uD99Frd9XcbFQ5SkRUT0VqTXc" target="_blank">ACUERDO
                    DE
                    CONSEJO DIRECTIVO POR AÑO</a></p>
        </li>
        <li>
            <p><a href="https://drive.google.com/drive/folders/1GYi33d1P8gRs-z8euyR6RnSL58XwMXJ_?usp=sharing" target="_blank">ASAMBLEA GENERAL POR AÑO</a></p>
        </li>
        <li>
            <p><a href="https://drive.google.com/drive/folders/1FEK9t3OUQ_YHmLl8kXy07hlDVQch-koR" target="_blank">ESTADOS FINANCIEROS AUDITADOS</a></p>
        </li>
    </ul>
</div>