<h2 class="title">
    Colegiaturas
</h2>
<div class="cole-main">
    <span class="subtitle">REQUISITOS PARA INCORPORACIÓN DE NUEVOS MIEMBROS</span>
    <div class="cole-dc">
        <ul>
            <li>TÍTULO PROFESIONAL AMBOS LADOS (DIGITAL)</li>
            <li>DNI AMBOS LADOS (DIGITAL)</li>
            <li>FOTOGRAFÍA A COLORES FONDO BLANCO (DIGITAL)</li>
            <li>PAGO ÚNICO DE S/. 990.00</li>
        </ul>
        <div class="card">
            <article>
                <h1>Opcional</h1>
                <p>Para la afiliación al Seguro Mutual
                    tienen derecho los Contadores que
                    no superen los 40 años de edad al
                    momento de la inscripción para colegiarse, la afiliación es voluntaria.
                    En caso desean afiliarse adjuntar
                    Copia de DNI de sus beneficiarios.
                </p>
            </article>
        </div>
    </div>
</div>
<div class="cole-main">
    <span class="subtitle">REQUISITOS PARA TRASLADO DE OTRO COLEGIO</span>
    <ul class="new-requis">
        <li>TÍTULO PROFESIONAL AMBOS LADOS (DIGITAL)</li>
        <li>DNI AMBOS LADOS (DIGITAL)</li>
        <li>FOTOGRAFÍA A COLORES FONDO BLANCO (DIGITAL)</li>
        <li>DOCUMENTO QUE ACREDITE COLEGIATURA EN OTRO COLEGIO (DIGITAL)</li>
        <li>PAGO ÚNICO DE S/. 390.00</li>
    </ul>
</div>
<div class="card note">
    <article>
        <h1 class="card-note-ti">Requisitos para Ceremonia de Colegiatura</h1>
        <ul>
            <li>La vestimenta obligatoriamente debe ser formal:
                <p> - Damas de preferencia sastre. <br>
                    - Caballeros de preferencia saco y corbata.</p>
            </li>
            <li>
                De preferencia usar el celular para que la señal sea fuerte y fluida.
            </li>
            <li>
                Deben de tener su micrófono apagado y solo encenderlo al momento de decir Sí juro”.
            </li>
            <li>
                Deben de estar en un ambiente iluminado, sin ruido y de preferencia con fondo blanco para la
                ceremonia.
            </li>
        </ul>
    </article>
</div>