<h2 class="title">
    Convenios Institucionales
</h2>
<p class="subtitle">El Colegio de Contadores de La Libertad ofrece a sus agremiados diversos convenios con importantes
    instituciones, para poder promover la participación de los agremiados con el Colegio.</p>
<div class="list-conv">
    <div class="convenio">
        <img src="./assets/img/convenios/convenio2.png" alt="">
        <div class="descrip">
            <span>CORPORACIÓN ESCACORP</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio1" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc1"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/ESCACORP.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio6.png" alt="">
        <div class="descrip">
            <span>PISCINA BERENDSON</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio4" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc4"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/PISCINABERENDSON.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio5.png" alt="">
        <div class="descrip">
            <span>GACETA JURÍDICA</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio6" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc6"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/GACETAJURIDICA.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio7.png" alt="">
        <div class="descrip">
            <span>UNIVERSIDAD CATÓLICA DE TRUJILLO BENEDICTO XVI</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio7" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc7"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/UCT.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio12.png" alt="">
        <div class="descrip">
            <span>AICOGESTIÓN</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio8" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc8"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/AICOGESTION.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio9.png" alt="">
        <div class="descrip">
            <span>QUANTUM CONSULTORES</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio9" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc9"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/QUANTUM.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio10.png" alt="">
        <div class="descrip">
            <span>I-CODE</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio10" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc10"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/ICODE.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio11.png" alt="">
        <div class="descrip">
            <span>PONTIFICA UNIVERSIDAD CATÓLICA DEL PERÚ – PUCP</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio11" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc11"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/PUCP.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio13.png" alt="">
        <div class="descrip">
            <span>UNIVERSIDAD ESAN</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio12" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc12"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/ESAN.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio14.png" alt="">
        <div class="descrip">
            <span>EDITORIAL ECONOMIA Y FINANZAS S.R.L.</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio13" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc13"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/SRL.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio15.png" alt="">
        <div class="descrip">
            <span>BERMANLAB</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio14" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc14"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/bermanlab.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio16.png" alt="">
        <div class="descrip">
            <span>LABORATORIO QUINTANILLA</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio15" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc15"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/quintanilla.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio17.png" alt="">
        <div class="descrip">
            <span>UNIR Y UNIR MEXICO</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio16" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc16"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/unir.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio18.png" alt="">
        <div class="descrip">
            <span>TOMONORTE</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio17" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc17"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/tomonorte.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio19.png" alt="">
        <div class="descrip">
            <span>FUNERARIA GAMARRA</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio18" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc18"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/funerariagamarra.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio20.png" alt="">
        <div class="descrip">
            <span>EL CULTURAL</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio19" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc19"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/elcultural.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio21.png" alt="">
        <div class="descrip">
            <span>LABORATORIO CLINICO SANAR</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio20" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc20"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/sanar.pdf'" class="btonspan">Ver
                convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio22.png" alt="">
        <div class="descrip">
            <span>PRACTICE EDUCATION</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio21" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc21"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/practiceeducation.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio23.png" alt="">
        <div class="descrip">
            <span>CLINICA DE OJOS OFTALMIVISIÓN</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio22" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc22"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/oftalmovision.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio24.png" alt="">
        <div class="descrip">
            <span>UK VIVENTIAL</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio23" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc23"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/ukvivential.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
    <div class="convenio">
        <img src="./assets/img/convenios/convenio25.png" alt="">
        <div class="descrip">
            <span>INSTITUTO PERUANO DE INVESTIGACIÓN Y DESARROLLO TRIBUTARIO - IPIDET</span>
        </div>
        <div class="convenio-options">
            <span [swal]="convenio24" class="btonspan">Ver más</span>
            <span [swal]="conveniodoc24"
                (click)="showpdfconvenio = 'https://ccpll.org/assets/archives/convenios/ipidet.pdf'"
                class="btonspan">Ver convenio</span>
        </div>
    </div>
</div>

<swal #convenio1>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio2.png" alt="">
        <div class="descrip">
            <span>CORPORACIÓN ESCACORP</span>
            <p>Este convenio ofrece descuento en tres de sus instituciones de la corporación, familiares directos -
                hijos, padres y conyugue, las cuales de detallan a continuación:
            </p>
            <ul>
                <li><b>ESCALABS:</b> Descuento de 20% si cancela en efectivo. En el caso de la prueba serológica
                    automatizada (CLIA) SARS- CoV-2 IgM / IgG (COVID - 19), se aplicará el mismo descuento.</li>
                <li>
                    <b>ESCAVAC:</b> Servicio de vacunación con el 10% de descuento.
                </li>
                <li>
                    <b>ESCAVET:</b> Servicio de análisis clínicos veterinarios con el 10% de descuento.
                </li>
                <li>
                    <b>ESCAMED:</b> Especializada en enfermedades infectocontagiosas con el 10% de descuento
                </li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc1>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #conveniodoc2>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio4>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio6.png" alt="">
        <div class="descrip">
            <span>PISCINA BERENDSON</span>
            <p>Institución educativa que incentiva la práctica de diversos deportes y que cuenta con más de 50 años
                liderando la enseñanza en natación. Ofrece a los agremiando, familiares directos y trabajadores
                descuentos especiales:</p>
            <ul>
                <li>
                    De 01 a 09 personas se aplica el 15% de descuento.
                </li>
                <li>
                    De 10 a 20 personas se aplica el 20% de descuento.
                </li>
                <li>De 29 a 29 personas se aplica el 30% de descuento.</li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc4>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio6>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio5.png" alt="">
        <div class="descrip">
            <span>GACETA JURÍDICA</span>
            <p>Por el presente convenio de cooperación interinstitucionales las partes acuerdan unir esfuerzos con el
                propósito de beneficiar a los miembros activos del COLEGIO, para lo cual GACETA JURIDICA, dentro del
                marco de su permanente compromiso con las instituciones y en especial con el gremio de los profesionales
                del derecho, en lo referente a proveerles herramientas de información</p>
        </div>
    </div>
</swal>
<swal #conveniodoc6>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio7>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio7.png" alt="">
        <div class="descrip">
            <span>UNIVERSIDAD CATÓLICA DE TRUJILLO BENEDICTO XVI</span>
            <ul>
                <li>
                    El presente convenio tiene por objetivo unir los esfuerzos del Colegio, y la universidad con miras a
                    la realización y profundización de actividades y de investigación conjunta en temas contables, de
                    gestión gubernamental y privada.
                </li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc7>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio8>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio12.png" alt="">
        <div class="descrip">
            <span>AICOGESTIÓN</span>
            <p>
                El objeto de este convenio es establecer un marco general de cooperación recíproca entre ambas
                instituciones, con el objetivo de realizar actividades académicas y de Investigación en forma conjunta
                sobre temas relacionados con el Control y Contabilidad
                de Gestión.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc8>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio9>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio9.png" alt="">
        <div class="descrip">
            <span>QUANTUM CONSULTORES</span>
            <p>El presente convenio tiene por objeto unir los esfuerzos de <b>LA ESCUELA y
                    EL COLEGIO,</b> con miras a la realización y profundización de actividades de capacitación
                permanente y de espacios de discusión conjunta en temas tributarios, Normas Internacionales de
                Información Financiera NIIF y laborales.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc9>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio10>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio10.png" alt="">
        <div class="descrip">
            <span>I-CODE</span>
            <p>El objetivo del presente convenio es establecer programas de especialización y certificación
                internacional en Normas Internacionales de Contabilidad para el sector público y privado para la
                formación de profesionales contables agremiados al
                CCPLL y público en general.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc10>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio11>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio11.png" alt="">
        <div class="descrip">
            <span>PONTIFICA UNIVERSIDAD CATÓLICA DEL PERÚ – PUCP</span>
            <p>El presente convenio tiene por objetivo unir los esfuerzos de LA PUCP y EL COLEGIO, con miras a la
                realización y profundización e actividades y de investigación conjunta en temas contables, de gestión
                gubernamental y privada.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc11>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio12>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio13.png" alt="">
        <div class="descrip">
            <span>UNIVERSIDAD ESAN</span>
            <p>El presente Convenio tiene por objeto permitir que los Miembros de la Orden y trabajadores del Colegio de
                Contadores Públicos de La Libertad, puedan participar en el proceso de admisión a los Programas de
                Posgrado de ESAN, de acuerdo con lo
                establecido en el Reglamento de Admisión correspondiente y, en caso de ser admitidos, ser asignados a
                los descuentos establecidos en el presente acuerdo, con las tarifas vigentes de cada programa.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc12>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio13>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio14.png" alt="">
        <div class="descrip">
            <span> EDITORIAL ECONOMIA Y FINANZAS S.R.L.</span>
            <p>
                Con la finalidad de favorecer el elevado desempeño profesional de sus miembros hábiles, el COLEGIO ha
                convenido con la EDITORIAL que durante el plazo del presente Convenio, sus miembros hábiles podrán
                acceder sin costo alguno a la información sistematizada y permanentemente actualizada que brinda GALILEO
                sobre el régimen legal de la Emisión Electrónica de Comprobantes de Pago.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc13>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio14>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio15.png" alt="">
        <div class="descrip">
            <span>BERMANLAB</span>
            <p>
                En virtud del presente convenio, Bermanlab se compromete a otorgar a sus colegiados y familiares
                directos (padres, conyugues e hijos) de el Colegio, presentando su Fotocheck que acredite que es
                referido El Colegio en las tarifas y beneficios.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc14>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio15>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio16.png" alt="">
        <div class="descrip">
            <span>LABORATORIO QUINTANILLA</span>
            <p>
                El presente convenio celebrado entre el laboratorio Quintanilla y el CCPLL para que preste servicios a
                sus agremiados, colaboradores y familiares directos, otorgándole un tarifario especial de descuento.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc15>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio16>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio17.png" alt="">
        <div class="descrip">
            <span>UNIR Y UNIR MEXICO</span>
            <p>
                Constituye el objeto del presente acuerdo establecer el marco general de colaboración entre CCPLL, UNIR
                y UNIR México en los ámbitos de la formación, la investigación, la transferencia tecnológica, el
                asesoramiento técnico especializado, el intercambio de experiencias, la difusión de la cultura y
                cualquier otra actividad en el ámbito de interés común de LAS PARTES, que dispongan de mutuo acuerdo.

                Así como establecer condiciones económicas especiales en favor de los colaboradores, y agremiados de
                CCPLL, así como a los familiares directos de estos, para que puedan cursar estudios de máster de UNIR, y
                Maestrías de UNIR México.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc16>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>


<swal #convenio17>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio18.png" alt="">
        <div class="descrip">
            <span>TOMONORTE</span>
            <p>Mediante el presente convenio TOMONORTE y EL COLEGIO DE CONTADORES se comprometen de mutuo acuerdo a
                realizar la toma de acciones establecidas en las cláusulas siguientes, a efecto de establecer lazos de
                cooperación interinstitucional.
            </p>
            <ul>
                <li>
                    - Descuento del 15% para los servicios de Resonancia Magnética, Rayos X, Tomografía Espiral
                    Multicorte – TEM y Densitometría Ósea.
                </li>
                <li>
                    - Descuento del 10% para el servicio de Laboratorio Clínico.
                </li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc17>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio18>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio19.png" alt="">
        <div class="descrip">
            <span>FUNERARIA GAMARRA</span>
            <p>En virtud del presente convenio, FUNERARIA GAMARRA, se obliga a prestar al COLEGIO DE CONTADORES, los
                servicios de descuentos a los Contadores Públicos Colegiados en el COLEGIO DE CONTADORES, que en
                adelante se acerquen a los establecimientos de FUNERARIA GAMARRA u comuniquen a la Central Telefónica
                910 703 654 la cual está a su servicio las 24 horas, para acercarnos al lugar de los hechos.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc18>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio19>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio20.png" alt="">
        <div class="descrip">
            <span>EL CULTURAL</span>
            <p>El centro de idiomas “El Cultural” otorga un descuento especial de S/20.00 en base al precio normal, en
                cualquier horario, por la matrícula en el Programa de Niños & Junior y de TAP (Teen & Adult Program) al
                contador y familiares (cónyuge e hijos).
            </p>
            <ul>
                <li>
                    - Descuento especial por convenio institucional a todos sus trabajadores y familiares (Cónyuge e
                    hijos) de “EL COLEGIO DE CONTADORES” que inicien por primera vez en la Institución.
                </li>
                <li>
                    - Facilidades de pago: Pago a cuenta sin recargo.
                </li>
                <li>
                    - Servicios adicionales gratuitos para los alumnos: Tutoría Personalizada, Laboratorio Multimedia,
                    Asesoría Educacional para Estudios en Estados Unidos, Biblioteca Bilingüe, entre otros.
                </li>
                <li>
                    - Descuento especial de S/20.00 en base al precio normal, en cualquier horario, por la matrícula en
                    el Programa de Niños & Junior y de TAP (Teen & Adult Program).
                </li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc19>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio20>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio21.png" alt="">
        <div class="descrip">
            <span>LABORATORIO CLINICO SANAR</span>
            <p>El presente convenio se realiza con el objeto de mantener beneficios recíprocos entre las partes,
                otorgando beneficios equitativos de acuerdo al servicio que estos brindan (Prestar servicios de análisis
                clínicos en áreas de Bioquímica, Inmunología, Hematología, Microbiología, Parasitología y Patología).
            </p>
            <p>El Laboratorio Clínico SANAR otorga a los miembros de la orden y familiares directos los siguientes
                descuentos:</p>
            <ul>
                <li>- 30% en exámenes de rutina (No aplica a promociones establecidas).</li>
                <li>- 20% en exámenes de rutina (No aplica a promociones establecidas).</li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc20>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio21>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio22.png" alt="">
        <div class="descrip">
            <span>LABORATORIO CLINICO SANAR</span>
            <p>La finalidad del presente convenio es desarrollar un programa de capacitación, cooperación e integración
                mutua, donde las partes se brinden facilidades institucionales.
                Los beneficios del presente convenio alcanzan a los miembros colegiados, familiares directos y
                trabajadores del Colegio de Contadores Públicos de La Libertad.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc21>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio22>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio23.png" alt="">
        <div class="descrip">
            <span>CLINICA DE OJOS OFTALMIVISIÓN</span>
            <p>CLINICA DE OJOS OFTALMOVISIÓN ofrece los contadores y familiares directos (Cónyuge e hijos) los
                siguientes descuentos:
            </p>
            <ul>
                <li>
                    20% descuento del costo normal de la consulta, la misma que incluye la medida computarizada de la
                    vista, evaluación y despistaje de enfermedades como sospecha de glaucoma, catarata, retinopatía
                    diabética, degeneración macular, fondo de ojo directo.
                </li>
                <li>20% descuento en exámenes auxiliares.</li>
                <li>20% descuento en procedimiento quirúrgico.</li>
            </ul>
        </div>
    </div>
</swal>
<swal #conveniodoc22>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio23>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio24.png" alt="">
        <div class="descrip">
            <span>UK VIVENTIAL</span>
            <p>EL COLEGIO DE CONTADORES PUBLICOS DE LA LIBERTAD y UK VIVENTIAL reconocen como propósito común, elevar el
                nivel de calidad de vida de la población en general; a la vanguardia de la educación y con la finalidad
                de apoyar a la comunidad en el aprendizaje del idioma inglés, suscriben el presente convenio.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc23>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>

<swal #convenio24>
    <div class="modalconvenios" *swalPortal>
        <img src="./assets/img/convenios/convenio25.png" alt="">
        <div class="descrip">
            <span>INSTITUTO PERUANO DE INVESTIGACIÓN Y DESARROLLO TRIBUTARIO - IPIDET</span>
            <p>El presente CONVENIO tiene por objetivo establecer los lineamientos y términos generales de colaboración
                y cooperación mutua entre las PARTES, fomentar el desarrollo y fortalecimiento de capacidades de los
                colaboradores y asociados de ambas entidades, estimular la investigación académica y la realización de
                actividades de formación y capacitación en áreas temáticas de mutuo interés, así como el intercambio de
                experiencias en temas técnicos, normativos y el desarrollo de otras capacidades que conllevan a un
                desempeño laboral óptimo, uso de infraestructura, equipamiento, entornos virtuales y otros que
                contribuyan en el cumplimiento de sus fines y objetivos institucionales.
            </p>
        </div>
    </div>
</swal>
<swal #conveniodoc24>
    <div class="modalconvenios" *swalPortal>
        <pdf-viewer [src]="showpdfconvenio" [autoresize]="true" [render-text]="false" style="display: block;">
        </pdf-viewer>
    </div>
</swal>