import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comisiones-tecnicas-y-de-estudio',
  templateUrl: './comisiones-tecnicas-y-de-estudio.component.html',
  styleUrls: ['./comisiones-tecnicas-y-de-estudio.component.css']
})
export class ComisionesTecnicasYDeEstudioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
