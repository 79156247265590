<h2 class="title">
    SEGURO MUTUAL
</h2>
<div class="band">
    <div class="item">
        <article class="card">
            <div>
                <h1><a href="https://drive.google.com/drive/folders/12h7MhisB8AK9QNqTXuDCamiTT6LDQYyW?usp=sharing">ADELANTO DE SEGUROS MUTUAL</a></h1>
            </div>
        </article>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <h1><a href="https://drive.google.com/drive/folders/1TgU_b-89C7aJo1mErP60rwHN0X_5OuIW?usp=sharing">ANÁLISIS CLÍNICOS</a></h1>
            </div>
        </article>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <h1><a href="https://drive.google.com/drive/folders/15mK7fc7hypWOiEe_0074KC8KZOUGaVLP?usp=sharing">INGRESOS Y EGRESOS DIARIOS</a></h1>
            </div>
        </article>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <h1><a href="https://drive.google.com/drive/folders/13Exj5uCv0-eBlCyTeoG5kAIPQBNPOv_B?usp=sharing">PÓLIZAS POR RECOGER</a></h1>
            </div>
        </article>
    </div>
    <div class="item">
        <article class="card">
            <div>
                <h1><a href="https://drive.google.com/drive/folders/18KWIo9B9rjVg1J3JNdU34MmKcRpDMn9w?usp=sharing">PAGOS DE SEGURO MUTUAL</a></h1>
            </div>
        </article>
    </div>
</div>